<script>
import Gen from '@helper/Gen';

export default {
  name: "VForm",
  props: {
    target: {
      default: ".table-loading"
    },
    method: {
      default: "get"
    },
    type: {
      default: "ajax"
    },
    action: {
      default: location.href,
    },
    data: {
      default: "",
    }
  },
  data(){
    return {
      loading: false,
    }
  },
  mounted:function(){
    $.validator.setDefaults({
      ignore: ".nv",
      errorPlacement: function (error, element) {
        var placement = $("[error='" + element.attr('name') + "']")
        var label = placement.attr('label') || element.attr('name')
        error.text(error.text().replace("{label}", label))
        if (placement.length) {
          placement.html(error)
        } else {
          error.insertAfter(element)
        }
      },
      invalidHandler:function(e, validator){
        if(validator.errorList.length && $("#tabs").length)
          $('#tabs a[href="#' + $(validator.errorList[0].element).closest(".tab-pane").attr('id') + '"]').tab('show')
      }
    })
    this.form = $(this.$el)
    this.form.validate()
    this.btnBefore = this.form.find(".btn-loading").html()
    this.form.submit((e)=>{
      e.preventDefault()

      if(!this.validate()) return
      if (this._events.resp) return this.$emit('resp', this)
      this.submit()
    });
  },
  methods: {
    validate: function(){
      return $(this.$el).valid()
    },
    btnLoading: function(){
      this.form.find(".btn-loading").html('<div class="loading-spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>');
      setTimeout(()=>{ this.loading = true }, 10);
      return this.loading
    },
    btnUnloading: function(){
      this.form.find(".btn-loading").html(this.btnBefore);
      this.loading = false
    },
    tableLoading: function(){
      if(!$(this.target+" .loading-spinner").length) $(this.target).append('<div class="loading-spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>')
      setTimeout(()=>{ this.loading = true }, 10);
      return this.loading
    },
    tableUnloading: function(){
      $(this.target+" .loading-spinner").remove();
      this.loading = false
    },
    submit: function(callback){
      if (this.loading) return
      this.loading = true

      /*
      * Loading table
      */
      if (this.type == "table") this.tableLoading()
      this.btnLoading()

      /*
      * Parameter submission
      */
      var $data = this.form.serialize();
      var $dataObject = Gen.toObject(this.form)

      // Run ajax submission
      $.ajax({
        url: this.action,
        type: this.method,
        data: this.data ? this.data : $dataObject,
        error: () => {
          setTimeout(()=>{ this.loading = false }, 1000)
        },
        success: (resp) => {
          setTimeout(()=>{ this.loading = false }, 1000)
          if (this.type == "table") {
            this.tableUnloading();
            App.query = Object.assign(App.query, $data);
            App.data = resp.data;
            App.paginate = resp.paginate;
            App.currentUrl = resp.url;
          }
          this.form.find("[name],[type='submit'],button").removeAttr('disabled');
          if (typeof btnloading == "undefined" && this.form.find(".btn-loading").text() == "") {
            this.btnUnloading()
          }
          if(callback) callback(resp)
          if (typeof resp != "object") eval(resp);
        },
      })
    }
  },
}
</script>

<template>
  <form><slot></slot></form>
</template>